




















































import { Component, Vue } from 'vue-property-decorator'
import ContentArea from '@/components/common/ContentArea.vue'
import ProgressLine from '@/components/ProgressLine.vue'
import store from '../../store'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import Title from '@/components/common/page-layout/Title.vue'
import { HighlightStatus } from '@/store/modules/walkthrough/types/pre-test/highlightStatus'
import { TestOutcomeHighlight } from '@/store/modules/walkthrough/types/outcomes/resultOverview/highlights/testOutcomeHighlight'
import { OutcomeScenario } from '@/store/modules/walkthrough/types/outcomes/outcomeScenario'
import { OutcomeType } from '@/store/modules/walkthrough/types/outcomes/common/outcomeType'

@Component({
  components: {
    Title,
    ContentArea,
    ProgressLine,
    PrimaryButton
  }
})
export default class Result extends Vue {
  public outcomeType: OutcomeType | null = null;
  public highlights: TestOutcomeHighlight[] = [];

  private isScrollEnd = false

  private scrollEnd (ev: { target: HTMLDivElement }): void {
    this.isScrollEnd = (ev.target.scrollTop + ev.target.clientHeight + 10) >= ev.target.scrollHeight
  }

  created (): void {
    const outcomeScenario = store.getters['walkthrough/outcomeScenario'] as OutcomeScenario

    if (!outcomeScenario || !outcomeScenario.resultOverview) {
      this.$router.push({ name: 'welcome' })
      return
    }

    this.sendData()

    if (outcomeScenario.outcomeType === OutcomeType.INCONCLUSIVE) {
      this.$router.push({ name: 'inconclusive outcome' })
      return
    }

    const { resultOverview } = outcomeScenario

    this.outcomeType = outcomeScenario.outcomeType
    this.highlights = resultOverview.highlights

    setTimeout(() => {
      this.restart()
    }, 60 * 1000)
  }

  isWarning (highlight: TestOutcomeHighlight): boolean {
    return highlight.status === HighlightStatus.warning
  }

  private restart (): void {
    const redirectUrl = localStorage.getItem('redirectUrl') as string
    const caseId = localStorage.getItem('caseId') as string
    const serialNumber = localStorage.getItem('serialNumber') as string
    const locale = localStorage.getItem('locale') as string

    if (redirectUrl !== 'undefined' && serialNumber !== 'undefined' && caseId !== 'undefined' && locale !== 'undefined') {
      window.location.href = redirectUrl + '?case_id=' + caseId + '&serial_number=' + serialNumber + '&locale=' + locale
    }
    // this.$router.push({ name: 'welcome' })
  }

  sendData (): void {
    this
      .$store
      .dispatch('walkthrough/completeTest')
  }
}
